import { styled } from '@mui/material/styles'
import { Link } from 'gatsby'
import { Typography } from '@mui/material'

export const BreadcrumbLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: white;
  p:hover {
    text-decoration: underline;
  }
  span {
    margin-right: 8px;
  }
`

export const BreadcrumbItem = styled(Typography)`
  display: flex;
  span {
    margin-right: 8px;
  }
`

export const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
