import { Button, styled } from "@mui/material";
import React from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import {
  Apartment,
  Badge,
  Home,
  Lock,
  Person,
  ShoppingBag,
} from "@mui/icons-material";

import SEO from "../components/common/seo";
import { routePaths } from "../constants/routes";
import Header from "../components/common/Header";
import { SubContainer } from "../components/common/Styled";

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
  }
`;

const OptionsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "20px",
  padding: "20px",
  tableLayout: "fixed",
  borderCollapse: "collapse",
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}));

const title = "Mon compte";

const ProfilePage = () => {
  const { home } = useStaticQuery(query);

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home,
    },
    {
      icon: <Person />,
      label: title,
    },
  ];

  return (
    <>
      <SEO title="Mon compte" />
      <SubContainer>
        <Header {...{ items, title }} />
        <OptionsContainer>
          <Button
            variant="outlined"
            fullWidth
            color="secondary"
            onClick={() => navigate(routePaths.orders)}
            startIcon={<ShoppingBag />}
          >
            Mes commandes
          </Button>
          <Button
            variant="outlined"
            fullWidth
            color="secondary"
            onClick={() => navigate(routePaths.addresses)}
            startIcon={<Apartment />}
          >
            Mes adresses
          </Button>
          <Button
            variant="outlined"
            fullWidth
            color="secondary"
            onClick={() => navigate(routePaths.identity)}
            startIcon={<Badge />}
          >
            Mes informations personnelles
          </Button>
          <Button
            variant="outlined"
            fullWidth
            color="secondary"
            onClick={() => navigate(routePaths.password)}
            startIcon={<Lock />}
          >
            Mot de passe
          </Button>
        </OptionsContainer>
      </SubContainer>
    </>
  );
};
export default ProfilePage;
