import React, { ReactNode } from 'react'
import { navigate } from 'gatsby-link'
import {
  Breadcrumbs,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { BreadcrumbItem, BreadcrumbLink, TitleContainer } from './Styled'

interface HeaderProps {
  items: {
    icon: ReactNode
    label: string
    route?: string
  }[]
  action?: {
    icon: ReactNode
    label: string
    route: string
  }
  title: string
  filter?: React.ReactNode
}

const Header = (props: HeaderProps) => {
  const { items, action, title, filter } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Breadcrumbs
        separator="›"
        maxItems={isMobile ? 2 : 3}
        aria-label="breadcrumb"
      >
        {items.map(({ icon, label, route }, index) => {
          if (index < items.length - 1) {
            return (
              <BreadcrumbLink to={route} key={label}>
                {icon}
                <Typography ml={0.5}>{label}</Typography>
              </BreadcrumbLink>
            )
          }
          return (
            <BreadcrumbItem color="textPrimary" key={label}>
              {icon}
              <Typography component="span" ml={0.5}>
                {label}
              </Typography>
            </BreadcrumbItem>
          )
        })}
      </Breadcrumbs>

      <TitleContainer>
        <Typography
          component="h1"
          variant="h2"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          {title}
        </Typography>
        {action && (
          <Tooltip arrow title={action.label}>
            <IconButton
              onClick={() => navigate(action.route)}
              color="secondary"
              style={{ height: 'fit-content' }}
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        )}
        {filter}
      </TitleContainer>
      <Divider />
    </>
  )
}

export default Header
